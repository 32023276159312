import { getNextPlanType } from '../../../utils/getNextPlanType'
import { setLockedFeatureModal } from '@dtx-company/inter-app/src/redux/slices/lockedFeatureModalSlice'
import { useBillingPlanType } from '../../../hooks/useBillingPlanType/useBillingPlanType'
import { useDispatch } from 'react-redux'
import { useLockedFeatureModalContext } from '../context/useLockedFeatureModalContext'

export const useLimitLockedFeatureModal = (): (({
  limitType,
  onOpen,
  onClose
}: {
  limitType: 'code_limit' | 'page_limit' | 'get_more_storage'
  onOpen?: () => void
  onClose?: (reason?: 'success' | 'dismiss') => void
}) => void) => {
  const dispatch = useDispatch()
  const billingPlanType = useBillingPlanType()
  const { setOnClose } = useLockedFeatureModalContext()

  const openLimitLockedFeatureModal = ({
    limitType,
    onOpen,
    onClose
  }: {
    limitType: 'code_limit' | 'page_limit' | 'get_more_storage'
    onOpen?: () => void
    onClose?: (reason?: 'success' | 'dismiss') => void
  }): void => {
    onOpen?.()
    dispatch(
      setLockedFeatureModal({
        lockedFeatureName: limitType,
        planType: getNextPlanType(billingPlanType)
      })
    )
    setOnClose(onClose)
  }
  return openLimitLockedFeatureModal
}
